import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import "./navigation.css"

const Navigation = ({data, width, closeMenu, visible, offset, mouseEnter}) => {
  const Arr = data.menuItems.nodes
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuId, setMenuId] = useState()
  const [activeSub, setActiveSub] = useState()
  const handler = closeMenu 

  const chevron = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 ml-2 stroke-blue"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  )
  const plus = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 ml-2 stroke-blue"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
    </svg>
  )
  const left = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 mr-2 stroke-blue"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
      />
    </svg>
  )

  // const isVis = props.visible
  const menuHandler = e => {
    if (menuId !== e) {
      setMenuId(e)
      setMenuOpen(true)
    } else {
      setMenuOpen(!menuOpen)
      setMenuId(e)
    }
  }

  useEffect(() => {
    console.log(visible)
    if (visible == false) {
      // console.log('doSomething')
      setActiveSub()
    }
  }, [visible])

  const menu = Arr.map((m, i) => {
    const menuItem =
      m.parentId == null ? (
        <li className={`menu-item ${m.cssClasses.join(" ")}`} onMouseEnter={()=>mouseEnter(m.id)}>
          {m.childItems.nodes.length > 0 && width < 1024 ? (
            <div className="flex flex-row items-center justify-between w-full lg:w-auto" onClick={() => { setActiveSub(i) }}>
              {/* <Link to={m.uri} onClick={handler}>{m.label}</Link> */}
              <span>{m.label}</span>
              <span onClick={() => { setActiveSub(i)}}>{plus}</span>
            </div>
          ) : (
            <Link to={m.uri} className={`menu-item__link`} onClick={handler} >
              <span>{m.label}</span>
              {m.childItems.nodes.length > 0 ? chevron : null}
            </Link>
          )}
          {m.childItems.nodes.length > 0 && width > 1024 ? (
            <>
            {/* <div className="fixed left-0 w-screen h-auto z-10 subitem-list bg-white flex-row justify-end p-5">
              <ul className={`m-0 grid grid-cols-4 gap-8 w-10/12`}>
                {m.childItems.nodes.map((c, i) => {
                  return (
                    <li
                      className={` h-full px-4 ${
                        i + 1 == m.childItems.nodes.length ? "" : "mb-2"
                      }`}
                    >
                      <Link to={c.uri}>{c.label}</Link>
                      <p className="mt-3 text-gray">{c.description}</p>
                    </li>
                  )
                })}
              </ul>
            </div> */}
            </>
          ) : m.childItems.nodes.length > 0 && width < 1024 ? (
            <div
              className={`subitem-list z-20 w-full h-screen absolute top-0 bg-white transition-all duration-300 ${activeSub == i ? "left-0" : "-left-full"}`}
            >
              <div
                className="bg-background flex flex-row items-center py-4 px-4 md:py-0 text-md lg:text-lg text-blue bg-light cursor-pointer"
                onClick={() => {
                  setActiveSub()
                }}
              >
                {left} Terug
              </div>
              <ul
                className={`px-0 text-md lg:text-lg grid grid-cols-1 md:mt-6 navigation `}
              >
                {m.childItems.nodes.map((c, i) => {
                  return (
                    <li className={`h-full text-blue m-0 py-[8px]`}>
                      <Link to={c.uri} onClick={handler}>
                        {c.label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          ) : null}
        </li>
      ) : null

    return menuItem
  })

  return (
    <ul className={`navigation`}>
      {menu}
    </ul>
  )
}

export default Navigation
