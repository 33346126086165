import { Link } from "gatsby";
import React from "react";

const MegaMenu = ({mouseLeave, selected, isVisible, vestigingen, menu}) => {

    const data = menu
    const Arr = data.menuItems.nodes

    const arrow = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.62 4.45337L13.6667 8.50004L9.62 12.5467" stroke="#D61B27" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.33337 8.5H13.5534" stroke="#D61B27" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
    
    const menuItems = Arr.map((menuItem) => (
        <>
                {
                menuItem.childItems.nodes.map((menu) => (
                
                (menu.childItems.nodes.length > 0) ?
                    <div key={menu.id} className="mega-menu">
                        <Link to={menu.path} className="m-0 font-medium text-base">{menu.label}</Link>
                        <ul className="m-0 mt-2 text-gray text-sm">
                            {menu.childItems.nodes.map((subItem) => (
                                <Link to={subItem.path} className="text-gray"><li key={subItem.id}>{subItem.label}</li></Link>
                            ))} 
                        </ul>
                    </div>

                :  (menu.childItems.nodes.length === 0) ?
                    <div key={menu.id} className="mega-menu">
                        <Link to={menu.path}><p className="m-0 font-medium text-base">{menu.label}</p></Link>
                        <p className="m-0 my-2 text-gray text-sm">{menu.description}</p>
                    </div>
                
                : null
                
                ))}
        </>
    ))

    return(
            <div className="mega__menu--container" onMouseLeave={()=>{mouseLeave()}}>
                    {/* {menuItems} */}
                    {
                        data.menuItems.nodes.map((item, i)=>{
                            if(item.childItems.nodes.length > 0){
                                return(
                                    <div class={`mega-menu__item ${item.id == selected ? 'active' : ''}`}>
                                        <div className={`mega-menu ${item.cssClasses.join(" ")} ${item.id == selected ? 'active' : ''}`}>
                                            <ul className="flex flex-row">
                                                {item.childItems.nodes.map((child, i)=>{
                                                    return(
                                                        <li className={`${child.cssClasses.join(" ")}`}>
                                                            <Link to={child.path}>
                                                                <img src={child.menu.afbeelding?.mediaItemUrl} />
                                                                <span>{child.label}{arrow}</span>
                                                                <div className="description" dangerouslySetInnerHTML={{__html: child.description}}></div>
                                                                <div className="button"><span className="z-10">{child.menu.knoptekst}</span></div>
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
            </div>        
    )
}

export default MegaMenu